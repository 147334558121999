.backimg {
  overflow: hidden;
  position: relative;
}

.backimg:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;

  display: block;
  background-image: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.8) 30%,
      rgba(57, 48, 131, 0.1)
    ),
    url("./resources/fanart3.jpeg");
  background-size: cover;
  background-repeat: no-repat;
  width: 100%;
  height: 100%;
  transform: scale(1.1);

  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
}

.backimgMobile {
  overflow: hidden;
  position: relative;
}

.backimgMobile:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;

  display: block;
  background-image: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.8) 30%,
      rgba(17, 18, 40, 0.1)
    ),
    url("./resources/verticalFanart.jpeg");
  background-size: cover;
  background-repeat: no-repat;
  width: 100%;
  height: 100%;
  transform: scale(1.1);

  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
}

.paketContainer:hover {
  transform: translateY(0) scale(1.05);
}

.ribbon-top {
  /* adjust the below to control the shape */
  --d: 5px;
  --g: 16px;
  --c: #333;
  /**/

  --d: 10px;
  --g: 20px;
  --c: rgb(57, 48, 131);
  width: 190px;

  position: absolute;
  top: 0;
  right: 0;
  transform: translate(29.29%, -100%) rotate(45deg); /* 29.29% = 100%*(1 - cos(45deg)) */
  color: #fff;
  text-align: center;
  transform-origin: bottom left;
  padding: 5px 0 calc(var(--d) + 5px);
  background: linear-gradient(
        135deg,
        transparent var(--g),
        var(--c) calc(var(--g) - 0.3px)
      )
      left,
    linear-gradient(
        -135deg,
        transparent var(--g),
        var(--c) calc(var(--g) - 0.3px)
      )
      right;
  background-size: 51% 100%;
  background-repeat: no-repeat;
  clip-path: polygon(
    0 0,
    100% 0,
    100% 100%,
    calc(100% - var(--d)) calc(100% - var(--d)),
    var(--d) calc(100% - var(--d)),
    0 100%
  );
}
